import http from "@/helpers/http";
import { pack } from "@/helpers/utils";
const service = "dashboard/";

export function listSaleData(form) {
	return http.request({
		url: service + "listSaleData",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function listChartData(form) {
	return http.request({
		url: service + "listChartData",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function listRevenueData(form) {
	return http.request({
		url: service + "listRevenueData",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function listSummarySaleData(form) {
	return http.request({
		url: service + "listSummarySaleData",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function genBill(form) {
	return http.request({
		url: service + "genBill",
		method: "POST",
		data: pack(form)
	})
}


export function exportForm(form) {
	return http.request({
		url: service + "export",
		method: "POST",
		data: pack(form)
	})
}
