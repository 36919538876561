import http from "@/helpers/http";
import { pack } from "@/helpers/utils";
const service = "faEmployee/";

export function listByPage(form) {
	return http.request({
		url: service + "listByPage",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function deleteById(id) {
	return http.request({
		url: service + "deleteById",
		method: "POST",
		params: {
			id: id
		}
	})
}

export function save(form) {
	return http.request({
		url: service + "save",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function updateById(form) {
	return http.request({
		url: service + "updateById",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
		},
		data: pack(form)
	})
}

export function listSummarySaleData(form) {
	return http.request({
		url: service + "listSummarySaleData",
		method: "POST",
		params: {
			page: form.currPage,
			pageSize: form.pageSize,
			id:form.id
		},
		data: pack(form)
	})
}

export async function listAll(form) {
	form.currPage = 1;
	form.pageSize = 100;
	let flag = true;//{code:"0000",message:"查询成功"}
	let listResult = {list:[]};
	while(flag){
	  let res = await listByPage(form);
	  let { code, result, message } = res;
	  if (code !== "0000") {
		if(form.currPage<=1){
		  return res;
		}else{
		  return {code:"0000",message:"查询成功",result:listResult};
		}
	  }
	  const { list, total } = result;
	  listResult.total = total;
	  listResult.list.push(...list);
	  if(total>form.currPage*form.pageSize){
		flag = true;
	  }else{
		flag = false;
	  }
	  form.currPage++;
	} 
	return {code:"0000",message:"查询成功",result:listResult};
  }