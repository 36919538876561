<template>
  <page-list noMarginFlag="false">
    <template #header>
      <div class="title">营收统计</div>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" inline="true">
        <el-form-item label="时间" prop="activityTime">
          <el-date-picker
            v-model="form.activityTime"
            type="daterange"
            value-format="YYYY-MM-DD"
            format="YYYY-MM-DD"
            range-separator=" - "
            popper-class="tpc"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="所属门店：" prop="shopId">
          <el-select
            v-model="form.shopId"
            placeholder="请选择"
            @change="shopChangeHandle"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in shopOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道" prop="channelId">
          <el-select
            v-model="form.channelId"
            placeholder="请选择"
            @change="channelOptions"
            :disabled="!form.shopId"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in channelOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属店员：" prop="employeeId">
          <el-select
            v-model="form.employeeId"
            placeholder="请选择"
            :disabled="!form.shopId"
            clearable
            filterable
          >
            <el-option
              v-for="(item, index) in employeeOptions"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="listByPage()">查 询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetHandle()">重 置</el-button>
        </el-form-item>
      </el-form>
    </template>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe
      v-loading="loading"
      element-loading-background="transparent"
      row-key="id"
    >
      <el-table-column type="index" align="center" width="60" label="序号"></el-table-column>
      <el-table-column
        v-if="false"
        prop="id"
        label="ID"
        min-width="40"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column prop="date" label="时间" header-align="center" align="center" min-width="120"></el-table-column>
      <el-table-column
        prop="shopInfo.name"
        label="门店"
        min-width="200"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="channelInfo.name"
        label="渠道"
        min-width="200"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="employeeInfo.name"
        label="员工"
        header-align="center"
        align="center"
        min-width="200"
      ></el-table-column>
      <el-table-column
        prop="totalNum"
        label="订单量"
        min-width="120"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalAmount"
        label="金额"
        min-width="120"
        header-align="center"
        align="center"
      ></el-table-column>
    </el-table>
    <pagination v-model="paginationData" :total="total" @change="listRevenueData"></pagination>
    <div ref="order" class="echarts"></div>
    <div ref="amount" class="echarts"></div>
  </page-list>
</template>

<script>
import { listRevenueData, listChartData } from "@/apis/unidrink/dashboard";
import { getShopOptions } from "@/apis/auth";
import { getChannelOptions } from "@/apis/unidrink/channel";
import { listAll } from "@/apis/unidrink/employee";
import { msgErr, msgSuc } from "@/helpers/message";
import * as echarts from "echarts";

import { cloneDeep } from "lodash";
import Sortable from "sortablejs";

export default {
  components: {},
  props: {
    mode: {
      type: String,
      default: "view" // view-页面；component-组件
    }
  },
  data() {
    return {
      tableData: [],
      paginationData: {},
      initForm: {
        shopInfo:{},
        channelInfo:{},
        activityTime:this.getActivity()
      },
      form: {
        
      },
      rules:[],
      total: 0,
      shopOptions: [],
      employeeOptions: [],
      channelOptions: [],
      loading: false,
      dialogVisible: false,
      editMode: "add",
      editData: {},
      amountChart: null,
      amountOption: {},
      orderChart: null,
      orderOption: {}
    };
  },
  watch:{
    "form.activityTime"(value){
      this.timeChangeHandle();
    }
  },
  emits: ["choose"],
  created() {
    this.form = cloneDeep(this.initForm);
    this.timeChangeHandle();
    getShopOptions(this.shopOptions);
  },
  mounted() {
    if (this.mode == "component") {
      this.listByPage();
    }
  },
  activated() {
    this.form = cloneDeep(this.initForm);
    this.timeChangeHandle();
    this.listByPage();
  },
  methods: {
    timeChangeHandle(){
      if(this.form.activityTime&&this.form.activityTime.length>=2){
          this.form.beginTime=parseInt(this.$moment(this.form.activityTime[0]).format('X'));
          this.form.endTime=parseInt(this.$moment(this.form.activityTime[1]).format('X'))+24*3600;
      }else{
        this.form.beginTime="";
          this.form.endTime="";
      }
    },
    listByPage(){
      this.listRevenueData();
      this.listChartData();
    },
    listRevenueData() {
      this.loading = true;
      listRevenueData({ ...this.form, ...this.paginationData })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.tableData = list;
          this.total = total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    listChartData() {
      this.loading = true;
      listChartData({ ...this.form, ...this.paginationData })
        .then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          let dateList =[];
          let amountList=[];
          let numList=[];
          list.forEach(item => {
            dateList.push(item.date);
            amountList.push(item.totalAmount);
            numList.push(item.totalNum);
          });
          this.initAmountCharts(amountList,dateList);
          this.initOrderCharts(numList,dateList);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetHandle() {
      //重置表单
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
        this.form = cloneDeep(this.initForm);
      }
    },
    initAmountCharts(amountList,dateList) {
      const amountChart = echarts.init(this.$refs.amount);
      const amountOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              show:true,
              backgroundColor: "#6a7985"
            }
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: dateList
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ],
        series: [
          {
            name: "总金额",
            type: "line",
            smooth: true,
            lineStyle:{
              width:0
            },
            areaStyle: {
              color: "#18bc9c"
            },
            emphasis: {
              focus: "series"
            },
            data: amountList
          }
        ]
      };
      amountChart.setOption(amountOption);
    },
    initOrderCharts(numList,dateList) {
      const orderChart = echarts.init(this.$refs.order);
      const orderOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            // label: {
            //   backgroundColor: "#6a7985"
            // }
          },
          showContent: true
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: dateList
          }
        ],
        yAxis: [
          {
            type: "value"
          }
        ],
        series: [
          {
            name: "订单量",
            type: "line",
            smooth: true,
            lineStyle:{
              width:0
            },
            areaStyle: {
              color: "#18bc9c"
            },
            emphasis: {
              focus: "series"
            },
            data: numList
          }
        ]
      };
      orderChart.setOption(orderOption);
    },
    /**
     * 选择门店
     * @param {Object} item
     */
    chooseShopInfoConfirm(item) {
      this.form.shopInfo = item;
      this.form.shopId = item.id;
    },
    /**
     * 选择渠道
     * @param {Object} item
     */
    chooseChannelConfirm(item) {
      this.form.channelInfo = item;
      this.form.channelId = item.id;
    },
    //获取当天0点
    getActivity(){
      let today = new Date();
      let yesterMonth = new Date(this.getXmonthToday(-1));
      return  [this.format(yesterMonth),this.format(today)];
    },
    format(date){
      var year = date.getFullYear();
      var month = (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1));
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      // yyyy-MM-dd
      var now = [year,month,day].join('-');
      return now;
    },
    getXmonthToday(type) {
      // type 0 是当天 -1 是上个月   1是下个月
      var now = new Date(); // 可以传值调式 now = new Date(2019,2,30); 今天是3月30号
      var year = now.getFullYear();//getYear()+1900=getFullYear()
      var month = now.getMonth() + 1;//0-11表示1-12月
      var day = now.getDate(); // 当天日期
      if (parseInt(month) < 10) {
        month = "0" + month;
      }
      if (parseInt(day) < 10) {
        day = "0" + day;
      }
      now = year + '-' + month + '-' + day; // 如果取当月日期可直接 return 返回

      var preMonth = parseInt(month)  - 1;
      preMonth = preMonth < 10 ? '0' + preMonth : preMonth; // 获取上个月的值
      var nextMonth = parseInt(month) + 1;
      nextMonth = nextMonth < 10 ? '0' + nextMonth : nextMonth; // 获取下个月个月的值

      if (parseInt(month) == 1 && type == -1) {//如果是1月份，要上个月 ，则取上一年的12月份
        return (parseInt(year) - 1) + '-12-' + day;
      } else if (parseInt(month) == 12 && type == 1) { // 如果是12月，要下个月，取下一年的1月
        return (parseInt(year) + 1) + '-01-' + day;
      }

      var preSize  = new Date(year, parseInt(month) - 1, 0).getDate();//上月总天数
      var nextSize = new Date(year, parseInt(month)+ 1, 0).getDate();//下月总天数
      console.log(preSize, nextSize)
      if (preSize < parseInt(day) && type == -1) {// 取上个月，如果上个月总天数小于本月今天，取上个月最后一天
        return year + '-' + preMonth + '-' + preSize;
      } else if (nextSize < parseInt(day) && type == 1) { // 如果下个月总天数小于本月今天，取下个月最后一天
        return year + '-' + nextMonth + '-' + nextSize;
      }

      if (type == -1) {
        return year + '-' + preMonth + '-' + day;
      } else if (type == 1) {
        return year + '-' + nextMonth + '-' + day;
      }else if(type == 0){
        return now;
      }
    },

    shopChangeHandle(){
      this.getEmployeeOptions();
      this.getChannelOptions();
    },

    getChannelOptions(){
      this.form.channelId = "";
      this.channelOptions=[];
      if(this.form.shopId){
        getChannelOptions(this.channelOptions, {shopId:this.form.shopId},false);
      }else{
        getChannelOptions(this.channelOptions, {},false);
      }
    },

    getEmployeeOptions(){
      this.form.employeeId = "";
      this.employeeOptions=[];
      if(this.form.shopId){
        listAll({shopId:this.form.shopId}).then(({ code, result, message }) => {
          if (code !== "0000") {
            msgErr(message);
            return;
          }
          const { list, total } = result;
          this.employeeOptions.push(...list.map((row) => {
            return {
              name: row.name.replace(/[&nbsp;|└]/g, ""),
              id: row.id,
            };
          }));
        });
      }
    }
  }
};
</script>

<style lang="scss">
.title {
  width: 100%;
  padding: 15px;
  font-size: 24px;
}
.echarts {
  width: 90%;
  margin: 0 15px;
  height: 200px;
}
</style>

